<template>
	<div>
		<top-bar
			:phone="defaults[locale].website.phone"
			:email="defaults[locale].website.email"
			:navigation="langmenu"
		/>
		<nav class="main-nav delay fix-on-scroll" :class="{ 'show-mobile-nav': showMobileNav }">
			<div class="row">
				<div class="columns column12">
					<div class="nav-bar">
						<nuxt-link class="logo" :to="localePath('/', locale)" />
						<div class="nav-content">
							<ul>
								<li class="menu-close" @click="hideMobileNav">
									<font-awesome-icon class="icon" icon="fal fa-times" size="1x" />
								</li>
								<li v-for="item in topmenu[locale]" :key="item.filename" class="nav-item">
									<span @click="hideMobileNav">
										<nuxt-link :to="item.filename">
											{{ item.header }}
										</nuxt-link>
									</span>
									<submenu
										v-if="item.submenu.length"
										:subitems="item.submenu"
										@hide-mobile-nav="hideMobileNav"
									/>
								</li>
								<li class="lang-nav mobile-only">
									<div v-for="item in langmenu" :key="item.filename" class="language">
										<nuxt-link
											class="flag"
											:class="item.language"
											:to="item.filename"
											@click="hideMobileNav"
										>
											<span>{{ $t(item.language) }}</span>
										</nuxt-link>
									</div>
								</li>
							</ul>
						</div>
						<div class="buttons">
							<div class="menu-button button mobile-only" @click="toggleMobileNav">
								<font-awesome-icon class="icon" icon="fal fa-bars" size="1x" />
								<span>{{ $t('buttonMenu') }}</span>
							</div>
							<a
								class="button phone mobile-only"
								:href="`tel:${defaults[locale].website.phone}`"
								aria-label="Call us"
							>
								<font-awesome-icon class="icon" icon="fal fa-phone" size="1x" />
								<span>{{ $t('buttonCall') }}</span>
							</a>
							<book-button class="button book-now cta reserve-now">
								<font-awesome-icon class="icon" icon="fal fa-calendar-alt" size="1x" />
								<span>{{ defaults[locale].website.book }}</span>
							</book-button>
						</div>
					</div>
				</div>
			</div>
		</nav>
	</div>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();

if (!defaults.value) {
	await fetchDefaults();
}

const showMobileNav = ref(false);

const { data: topmenu } = await useWebsiteFetch('topmenu');

const langmenu = computed(() => {
	return (page.value && page.value.langmenu) ?? [];
});

const toggleMobileNav = () => {
	showMobileNav.value = !showMobileNav.value;
};

const hideMobileNav = () => {
	showMobileNav.value = false;
};
</script>

<style lang="scss" scoped>
@media (min-width: 1200px) {
	.nav-item:hover :deep(.submenu ul) {
		display: block;
	}
}

.mobile-only {
	display: none !important;
}

.main-nav {
	position: absolute;
	top: 41px;
	left: 0;
	right: 0;
	z-index: 1000;
	transition: background 0.3s ease-in-out;
	background: rgba(0 0 51 / 30%);
	backdrop-filter: blur(4px);

	.logo {
		width: 204px;
		height: 186px;
		background: url('~/assets/images/logo-herangtunet-full-bg.png') no-repeat center center;
		background-size: 204px;
		margin: -43px 0;
		transition: all 0.3s ease-in-out;
	}

	&.fixed {
		position: fixed;
		top: 0;
		background: rgba(0 0 51 / 60%);

		.logo {
			width: 109px;
			height: 99px;
			background: url('~/assets/images/logo-herangtunet-small-bg.png') no-repeat center center;
			background-size: 109px;
			margin: 0;
		}
	}

	ul li {
		display: inline-block;
		padding: 5px 0;
	}
}

.nav-bar {
	display: flex;
	align-items: center;
	padding: 0;
	z-index: 9;
	position: relative;

	.buttons {
		display: flex;
		align-items: center;
		gap: 10px;

		.button {
			padding: 15px 30px;
			margin: 0;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.icon {
			display: none;
			margin-right: 5px;
			font-size: 13px;
		}
	}
}

.menu-button {
	cursor: pointer;
}

.nav-content {
	margin: auto;

	a {
		color: #fff;
		text-decoration: none;
		text-transform: uppercase;
		margin: 0 15px;
		font-family: var(--heading-font-family);
		font-weight: 400;
		letter-spacing: 1px;
		transition: all 0.3s ease-in-out;
		border-bottom: 2px solid rgba(0 0 0 / 0%);
		padding: 0 0 7px;
		font-size: calc(var(--body-font-size) - 1px);

		&.active,
		&:hover {
			color: #fff;
			border-color: #fff;
		}
	}

	.menu-close {
		color: #fff;
		display: none;
		font-size: 30px;
		cursor: pointer;
		position: absolute;
		top: 15px;
		width: auto;
		right: 20px;
		padding: 0;
		margin: 0;
	}

	.lang-nav {
		margin: 25px auto;
		padding: 15px 0 0;
		border-top: 1px solid #6d6d6d;
		display: inline-block;
		max-width: 400px;

		.language {
			margin: 20px 0;

			.flag::before {
				content: '';
				width: 16px;
				position: relative;
				top: 3px;
				height: 16px;
				margin-right: 6px;
				display: inline-block;
			}

			.flag.en::before {
				background: url('~/assets/images/en.png') no-repeat;
			}

			.flag.nl::before {
				background: url('~/assets/images/nl.png') no-repeat;
			}

			.flag.de::before {
				background: url('~/assets/images/de.png') no-repeat;
			}

			.flag.fr::before {
				background: url('~/assets/images/fr.png') no-repeat;
			}

			.flag.es::before {
				background: url('~/assets/images/es.png') no-repeat;
			}

			.flag.no::before {
				background: url('~/assets/images/no.png') no-repeat;
			}
		}

		a {
			font-size: 15px;
			color: #fff;

			&.router-link-exact-active,
			&:hover {
				color: var(--cta-color);
			}
		}

		img {
			max-width: 16px;
			margin: -2px 5px 0 0;
			vertical-align: middle;
		}
	}
}

@media (max-width: 1280px) {
	.nav-bar a {
		font-size: 14px;
	}
}

@media (max-width: 1200px) {
	.mobile-only {
		display: flex !important;

		&.lang-nav {
			display: inline-block !important;
			margin: 50px auto -50px;

			.language {
				margin: 10px auto;
				text-align: left;
				width: 55%;
				padding: 0 0 0 20px;
			}
		}
	}

	.nav-bar .buttons {
		.icon {
			display: unset;
			font-size: 17px;
			margin: 0 10px 0 0;
		}

		.menu-button {
			background: #fff;
			color: #000;
		}
	}

	.main-nav {
		position: fixed !important;
		top: 0;
		background: rgba(0 0 51 / 60%);

		.logo {
			width: 109px;
			height: 99px;
			background: url('~/assets/images/logo-herangtunet-small-bg.png') no-repeat center center;
			background-size: 109px;
			margin: 0;
		}

		&.show-mobile-nav {
			.nav-content ul {
				display: flex;
				flex-direction: column;
				justify-content: center;
				opacity: 1;
				visibility: visible;
				transition: opacity 0.3s ease-in-out;
				height: 100vh;

				li:not(.menu-close) {
					width: 100%;
				}

				li:not(.lang-nav, .menu-close) {
					margin: 8px 0;

					a {
						font-size: 20px;
						font-weight: 300;
					}
				}
			}
		}

		ul {
			position: fixed;
			inset: 0;
			background: rgba(0 0 51 / 90%);
			backdrop-filter: blur(34px);
			text-align: center;
			padding: 20px 0;
			opacity: 0;
			visibility: hidden;
			overflow-y: scroll;
		}
	}

	.nav-content {
		a {
			padding: 0 10px 5px;
		}

		.menu-close {
			display: block;
		}
	}

	.logo {
		h3 {
			font-size: 22px;
		}

		h4.small {
			font-size: 18px;
		}
	}
}

@media (max-width: 880px) {
	.nav-bar .buttons .button {
		padding: 16px;

		.icon {
			margin-right: 0;
			font-size: 17px;
		}

		span {
			display: none;
		}
	}
}

@media (max-width: 580px) {
	.nav-bar .buttons span {
		display: none;
	}

	.nav-bar .logo {
		font-size: 22px;
	}
}
</style>
