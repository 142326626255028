<template>
	<div class="submenu-container">
		<span class="mobile-dropdown" :class="{ active }" @click="active = !active" />
		<div class="submenu" :class="{ active: hideAll ? resetState() : active }">
			<ul>
				<li v-for="item in subitems" :key="item.filename">
					<nuxt-link :to="`${item.filename}`" @click="hideMobileNav">
						{{ item.header }}
					</nuxt-link>
				</li>
			</ul>
		</div>
	</div>
</template>

<script setup>
defineProps({
	subitems: { type: Array, default: () => [] },
	hideAll: { type: Boolean, default: false },
});

const emit = defineEmits(['hide-mobile-nav']);

const active = ref(false);

const resetState = () => {
	active.value = false;
};

const hideMobileNav = () => {
	emit('hide-mobile-nav');
};
</script>

<style lang="scss" scoped>
.submenu-container {
	position: relative;
}

a {
	font-weight: 400;
	color: #fff;

	&.active,
	&:hover {
		color: var(--cta-color);
	}
}

.mobile-dropdown {
	display: none;
	width: 12px;
	height: 8px;
	background: url('~/assets/images/icon-dropdown-white.png') center center no-repeat;
	margin-bottom: 3px;

	&.active {
		transform: rotate(180deg);
	}
}

.submenu {
	ul {
		display: none;
		background: rgba(229 229 234 / 80%);
		position: absolute;
		top: 5px;
		left: 0;
		text-align: left;
		min-width: 200px;

		&.active {
			display: block;
		}

		li {
			padding: 0;
			list-style: none;
			display: flex;
			flex-flow: row nowrap;
			width: 100%;

			a {
				margin: 0;
				text-decoration: none;
				font-weight: 300;
				color: var(--header-color);
				font-family: var(--heading-font-family);
				text-transform: uppercase;
				background: none;
				width: 100%;
				padding: 12px 18px;
				font-size: calc(var(--body-font-size) - 1px);

				&:hover {
					background: #fff;
				}
			}
		}
	}

	&.active ul {
		display: block;
	}
}

@media (max-width: 1200px) {
	.mobile-dropdown {
		display: inline-block;
	}

	.submenu-container {
		display: inline;
	}

	.submenu ul {
		position: static;
		background: transparent;
		text-align: center;
		text-transform: uppercase;
		padding: 15px;

		li {
			a {
				color: #fff;
				background: none;

				&:hover {
					background: none;
				}
			}
		}
	}
}
</style>
